/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable unicorn/consistent-function-scoping */
import { DocumentNode } from 'graphql';

const dataManipulation = () => {
  const geminiGrnToUid = (grnString: string) => {
    if (!grnString.includes('grn:') || !grnString.includes('::')) {
      throw new Error('Provided string is not a valid GRN.');
    }

    const grnArray = grnString.split('::');

    if (grnArray.length !== 2 || grnArray[1].length === 0) {
      throw new Error('Could not find uuid from provided GRN.');
    }

    return grnArray[1];
  };

  const arrayIsValidAndHasItems = (array: []): boolean => array && Array.isArray(array) && array.length > 0;

  const objectIsNotEmpty = (object: Record<string, unknown>): boolean =>
    object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;

  const vueObjectToNormalObject = (object: Record<string, unknown>): Record<string, unknown> => ({ ...object });
  const vueArrayToNormalArray = (array: []): [] => [...array];

  const daysFromNowTo = (daysToAdd: number) => {
    try {
      const ms = Date.now() + daysToAdd * 24 * 60 * 60 * 1000;
      const date = new Date(ms);
      return {
        day: date.getDate().toLocaleString(),
        month: date.toLocaleString('en-US', { month: 'long' }),
        year: date.getFullYear().toLocaleString(),
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const stringIsUUID = (string: string) => /^[\da-f]{8}(?:\b-[\da-f]{4}){3}\b-[\da-f]{12}$/gi.test(string);

  const isValidUrl = (urlString: string) => {
    const pattern =
      '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$';
    const urlPattern = new RegExp(pattern, 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const beautifyAttributeName = (string: string) => {
    if (!string.includes('sdc') || !string.includes('_')) return null;
    const attributeNameWithoutPrefix = string.replace('sdc_', '').replace('_attribute', '');
    const splitAttributeName = attributeNameWithoutPrefix.split('_');
    return splitAttributeName.map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`).join(' ');
  };

  const randomInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

  const randomDateAndNumber = () => `${Date.now()}-${randomInteger(1, 99_999)}`;

  const findErrorInResponse = (error: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const errorKeys = Object.keys(error);
    // eslint-disable-next-line no-restricted-syntax
    for (const key of errorKeys) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (error[key]) {
        return key;
      }
    }
    return false;
  };

  const getErrorDataFromPlaceResponse = (error: any, errorType: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
    const code = Number.parseInt(error?.[errorType]?.name, 10);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const message = error?.[errorType]?.message;
    if (!Number.isNaN(code)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { code, message };
    }
    return { code: null, message: null };
  };

  const getErrorDataForNotification = (errorCode: string, code?: number, errorMessage?: string | null): { key: string; message: string | null } => {
    if (code < 50) {
      return { key: `${errorCode}_50`, message: null };
    }
    if (code >= 50 && code < 100) {
      return { key: `${errorCode}_50_100`, message: null };
    }
    if (code >= 100 && code < 200) {
      return { key: `${errorCode}_100_200`, message: errorMessage };
    }
    if (code >= 200) {
      return { key: `${errorCode}_200`, message: null };
    }
    return { key: errorCode, message: null };
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const removeNullishFromObject = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));

  const generateUniqueKey = () => Math.random().toString(16).slice(2);

  const wait = (ms: number): Promise<void> => {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  /**
   * Formats a date string into the "DD/MM/YYYY" format.
   *
   * @param {string} incomingDate - The date string to format. This should be in a format that can be passed to the Date constructor.
   * @returns {string} The formatted date string.
   */
  const formatDate = (incomingDate: string): string => {
    const date = new Date(incomingDate);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const getGqlStringFromDocumentNode = (doc: DocumentNode): string => doc.loc && doc.loc.source.body;

  // http://blog.marketto.it/2016/01/regex-validazione-codice-fiscale-con-omocodia/
  const fiscalCodeRegex =
    /^(?:[a-z][aeiou][aeioux]|[aeiou]x{2}|[b-df-hj-np-tv-z]{2}[a-z]){2}(?:[\dl-np-v]{2}(?:[a-ehlmpr-t](?:[04lq][1-9mnp-v]|[15mr][\dl-np-v]|[26ns][0-8l-np-u])|[dhps][37pt][0l]|[acelmrt][37pt][01lm]|[ac-ehlmpr-t][26ns][9v])|(?:[02468lnqsu][048lqu]|[13579mprtv][26ns])b[26ns][9v])(?:[a-mz][1-9mnp-v][\dl-np-v]{2}|[a-m][0l](?:[1-9mnp-v][\dl-np-v]|[0l][1-9mnp-v]))[a-z]$/i;

  const isValidItalianFiscalCode = (fiscalCode: string): boolean => {
    try {
      return fiscalCodeRegex.test(fiscalCode);
    } catch (error) {
      console.error('dataManipulation ~ isValidItalianFiscalCode ~ error:', error);
    }
    return false;
  };

  const isValidEuropeanVat = (vat: string): boolean => {
    const vatRegex =
      /^(atu\d{8}|be[01]\d{9}|bg\d{9,10}|hr\d{11}|cy\d{8}[a-z]|cz\d{8,10}|de\d{9}|dk\d{8}|ee\d{9}|el\d{9}|es[a-z]\d{7}(?:\d|[a-z])|fi\d{8}|fr[\da-z]{2}\d{9}|gb(\d{9}(\d{3})?|[a-z]{2}\d{3})|hu\d{8}|ie\d{7}[a-z]{1,2}|ie\d[a-z]\d{5}[a-z]|it\d{11}|lt(\d{9}|\d{12})|lu\d{8}|lv\d{11}|mt\d{8}|nl\d{9}b\d{2}|pl\d{10}|pt\d{9}|ro\d{2,10}|se\d{12}|si\d{8}|sk\d{10})$/i;
    try {
      return vatRegex.test(vat);
    } catch (error) {
      console.error('dataManipulation ~ isValidEuropeanVat ~ error:', error);
    }
    return false;
  };

  const isValidSDI = (sdi: string): boolean => {
    const sdiRegex = /^$|^(\w{7})$/i;
    try {
      return sdiRegex.test(sdi);
    } catch (error) {
      console.error('dataManipulation ~ isValidSDI ~ error:', error);
    }
    return false;
  };

  return {
    geminiGrnToUid,
    arrayIsValidAndHasItems,
    objectIsNotEmpty,
    vueObjectToNormalObject,
    vueArrayToNormalArray,
    daysFromNowTo,
    stringIsUUID,
    beautifyAttributeName,
    isValidUrl,
    randomInteger,
    findErrorInResponse,
    randomDateAndNumber,
    removeNullishFromObject,
    generateUniqueKey,
    getErrorDataFromPlaceResponse,
    getErrorDataForNotification,
    wait,
    formatDate,
    getGqlStringFromDocumentNode,
    isValidItalianFiscalCode,
    isValidEuropeanVat,
    isValidSDI,
  };
};

export default dataManipulation;

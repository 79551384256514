export type PageOptionValue = {
  it?: string;
  en?: string;
  es?: string;
  de?: string;
  fr?: string;
};
export type PageOptionsType = Array<PageOptionValue>;
const pagesOptions = [
  {
    it: 'blog',
    en: 'blog',
  },
  {
    it: 'contatti-it',
    en: 'contacts-en',
  },
  {
    it: 'guida-alle-taglie-it',
    en: 'size-guide',
  },
  {
    it: 'guida-taglie-cane',
    en: 'dog-size-guide',
  },
  {
    it: 'guida-taglie-gatto',
    en: 'cat-size-guide',
  },
  {
    it: 'chi-siamo-it',
    en: 'about-us-en',
  },
  {
    it: 'cart',
    en: 'cart',
  },
  {
    it: 'cookie-policy-it',
    en: 'cookies-policy',
  },
  {
    it: 'privacy-policy-it',
    en: 'privacy-policy-en',
  },
  {
    it: 'condizioni-generali-di-vendita-it',
    en: 'terms-and-conditions',
  },
  {
    it: 'faq-it',
    en: 'faq-en',
  },
  {
    it: 'my-account/i-miei-ordini',
    en: 'my-account/i-miei-ordini',
  },
  {
    it: 'my-account/i-miei-indirizzi',
    en: 'my-account/i-miei-indirizzi',
  },
  {
    it: 'my-account/il-mio-profilo',
    en: 'my-account/il-mio-profilo',
  },
  {
    it: 'my-account/my-wishlist',
    en: 'my-account/my-wishlist',
  },
  {
    it: 'reset-password',
    en: 'reset-password',
  },
  {
    it: 'checkout/shipping',
    en: 'checkout/shipping',
  },
  {
    it: 'checkout/billing',
    en: 'checkout/billing',
  },
  {
    it: 'checkout/payment',
    en: 'checkout/payment',
  },
  {
    it: 'checkout/thank-you',
    en: 'checkout/thank-you',
  },
] as PageOptionsType;
export default pagesOptions;
